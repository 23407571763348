import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout.js';

export default function IndexPage() {
  return (
    <Layout
      title="Quiénes somos Colibrí"
      description="Más información sobre Colibrí Revista"
    >
      <h1>About us</h1>
      <Link to="/">Back to home</Link>
    </Layout>
  );
}
